//nastaveni pro VUE
//globalThis.__VUE_OPTIONS_API__ = true;
//globalThis.__VUE_PROD_DEVTOOLS__ = false;
import "./admin.less";

console.log(44);

import { LadzoConsole as Lc } from "./js/global/ladzo-console.js";
import { MojeFce as Fce } from "./js/global/fce.js";
import { Top } from "./js/class/Top.js";
import * as edn from "./js/page/edit_novy.js";
import * as edForm from "./js/page/edit_form.js";
import * as detailProduktu from "./js/page/admin_detail_produktu.js";
//import * as produkty from "./js/incl/produkty.js";
//import * as ed from "./js/incl/editor.js";
/*
import * as detail from "./js/page/detail.js";
import * as pokus from "./js/incl/pokus.js";
import * as kosik from "./js/page/kosik.js";
import * as objednavka from "./js/page/objednavka.js";
import { Kosik as ClsKosik } from "./js/class/Kosik.js";
*/

var Url = new URL(window.location.href);
Top.localhost = false;
if (process.env.NODE_ENV == "development" || Url.searchParams.has("debug")) {
  Lc.zobraz({ version: "1.0.001", appName: "velosprint" });
}
//Lc.log("stav", process.env.NODE_ENV);
if (process.env.NODE_ENV == "development") {
  Lc.log("toto nema fungovat");
  Top.localhost = true;
  Top.ladzoServer = true;

  let b = Fce.get(window, "Ladzo.localhost") || false;
  if (!b) Top.localhost = false;
}

let bb = Fce.get(window, "ladzoServer") || false;
if (bb) {
  Top.ladzoServer = true;
}

//Lc.log("test");
//Lc.log("Top", Top);

$(function() {
  let bodyId = $("body").attr("id");
  if (bodyId == "bdEditEdx") {
    edn.uprava_stranky();
  } else if (bodyId == "bdFormEdx") {
    edForm.uprava_stranky();
  } else if (bodyId == "bdAdmDetProdukt") {
    detailProduktu.uprava_stranky();
  }
  //ed.test();

  //if (Top.localhost) pokus.mojeUpravy();
});
