import { MojeFce as Fce } from "../global/fce.js";
var Top = {
  localhost: false, //skript pousten pouze prez vlastni html testovaci strankuwindow.ladzoServer=true;
  ladzoServer: false, //true vzdy pokud jde o testovani
  nodeServer: false, //true vzdy pokud jde o testovani
  videaProduktu: false,
  obr_editor_src: "https://admin.velosprint.sk/obr/edit/",
  url_link: "https://www.velosprint.sk/",
  url: "www.velosprint.sk",
  admin_url: "https://admin.velosprint.sk/",
  admin_test_url: "https://admin.velosprint.tld/",
  svatky: [],
  velikonocni_pondeli: [],
  Drobecky: {},
  screenMobil: false,
  Screen: {
    "1199": false,
    "991": false
  },
  is_mobil: function() {
    return this.screenMobil;
  },
  is_screen: function(size) {
    return this.Screen[size];
  },
  _init: function() {
    if ($("body").hasClass("mobile")) {
      this.screenMobil = true;
    }
    if (window.innerWidth <= 767) {
      this.screenMobil = true;
    }
    if (window.innerWidth <= 1199) {
      this.Screen["1199"] = true;
    }
    if (window.innerWidth <= 991) {
      this.Screen["991"] = true;
    }

    let o = Fce.prom("svatky") || false;
    if (o) {
      this.svatky = o.a.split(",");
      this.velikonocni_pondeli = o.p.split();
    }
    this.nacti_drobecky();
  },
  nacti_drobecky: function() {
    let t = this;
    $(".breadcrumbs >span").each(function(index) {
      let span = $(this);
      if (span.attr("id") == "navigation-first") return;

      let a = span.find(">a");
      let link = "";
      let urlLink = false;
      if (a.length) {
        link = a.find(">span").text();
        urlLink = a.attr("href");
      } else {
        link = span.find('span[itemprop="name"]').text();
        urlLink = false;
        t.Drobecky["pocet"] = index;
      }

      t.Drobecky["level_" + index] = {
        text: link,
        url: urlLink
      };
    });
  },
  odh_pocet_dnu: function(pocet) {
    if (pocet == 1) {
      return "1 den";
    } else if (pocet < 5) {
      return pocet + " dni";
    } else {
      return pocet + " dní";
    }
  }
};

Top._init();

export { Top };
