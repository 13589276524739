import { LadzoConsole as Lc } from "../global/ladzo-console.js";
var neon = require("neon-js");

function neon_decode(val) {
  let txtOut = $('form.frmEdit textarea[name="vysledek"]');
  try {
    //var result = neon.decode("foo: {bar: 123}");
    var result = neon.Dumper.toText(neon.decode(val));
    //var data = { foo: "bar" };
    //var result = neon.encode(data, neon.BLOCK);
    Lc.log("restult", result);
    txtOut.val(result);
  } catch (e) {
    if (e instanceof neon.Error) {
      Lc.err(e);
    }
    //throw e;
    Lc.err(e);
  }
}

function neon_textarea() {
  let txtIn = $('form.frmEdit textarea[name="edx_neon_admin"]');
  neon_decode(txtIn.val());
  txtIn.keyup(function() {
    Lc.log("change");

    neon_decode($(this).val());
  });

  txtIn.on("keydown", function(event) {
    if (event.which == 9) {
      var start = this.selectionStart;
      var end = this.selectionEnd;

      var target = event.target;
      var value = target.value;

      // set textarea value to: text before caret + tab + text after caret
      target.value = value.substring(0, start) + "\t" + value.substring(end);

      // put caret at right position again (add one for the tab)
      this.selectionStart = this.selectionEnd = start + 1;

      event.preventDefault();
    }
  });
}

export function uprava_stranky() {
  Lc.f("uprava_stranky");
  neon_textarea();
}
