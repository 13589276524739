/**
 *
 *  pouziti
 *   LadzoConsole.zobraz({version:'1.0.1'});
 *   LadzoConsole.log('zprava');
 *
 *  pouzito: napr 5Mfencing
 **/

var LadzoConsole = {
  zobrazit: false,
  prefix:
    "\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771\u2771   ", //zatim nepouzivam
  _default: {
    appName: "Ladzo",
    version: "1.0.0"
  },
  setting: {},

  log: function() {},
  h: function() {},
  e: function() {},
  f: function() {},
  t: function() {
    //trigger
  },
  c: function() {
    //--ruzna konfikugrace treti strany
  },
  ajx: function() {
    //--ajax
  },
  err: function() {
    //--error
  },
  ctch: function() {
    //--error
  },
  promise: function() {
    //--promise
  },
  _init: function() {
    if (!this.zobrazit) {
      return false;
    }
    let $prfx = this.prefix;
    //this.log= console.log.bind(window.console,$prfx);
    this.log = console.log.bind(window.console);
    //this.h= console.log.bind(window.console,$prfx);

    //this.h= console.log.bind(window.console,$prfx,"%ccontext: ","font-weight:bold;");
    //this.h= console.log.bind.call(console.log, console, "%ccontext: ", "font-weight:bold;");
    this.h = console.log.bind.call(
      console.log,
      console,
      "%c" + $prfx,
      "font-weight:bold;background:yellow"
    );
    this.e = console.log.bind.call(
      console.log,
      console,
      "%c----event---------",
      "font-weight:bold;background:green;color:white"
    );
    this.f = console.log.bind.call(
      console.log,
      console,
      "%c----funkce---------",
      "font-weight:bold;background:#A0CFF3;color:white"
    );
    this.t = console.log.bind.call(
      console.log,
      console,
      "%c----trigger---------",
      "font-weight:bold;background:#000;color:white"
    );
    this.c = console.log.bind.call(
      console.log,
      console,
      "%c----config---------",
      "font-weight:bold;background:pink;color:white"
    );
    this.ajx = console.log.bind.call(
      console.log,
      console,
      "%c----ajax---------",
      "font-weight:bold;background:yellow;color:black"
    );
    this.err = console.log.bind.call(
      console.log,
      console,
      "%c----error--------",
      "font-weight:bold;background:red;color:white"
    );
    this.ctch = console.log.bind.call(
      console.log,
      console,
      "%c----catch--------",
      "font-weight:bold;background:brown;color:white"
    );
    this.promise = console.log.bind.call(
      console.log,
      console,
      "%c----promise--------",
      "font-weight:bold;background:pink;color:white"
    );
  },

  zobrazVerzi: function($verze) {
    if (!this.zobrazit) return false;

    this.log("%cverze aplikace: " + $verze, "background:green;color:white");
  },
  zobraz: function(setting = {}) {
    this.zobrazit = true;
    this._init();
    this.setting = Object.assign(this._default, setting);

    this.zobrazVerzi(this.setting.version);
  }
};

export { LadzoConsole };
