//import expand from "./emmet.es.js";

import { LadzoConsole as Lc } from "./ladzo-console.js";

export function implode($c, $separator = ", ") {
  var $arr = [];

  if (!$c.length) return false;

  $c.forEach(function(entry) {
    $arr.push(entry);
  });

  return $arr.join($separator);
}

export function uniqueArray(arr) {
  var a = [];
  for (var i = 0, l = arr.length; i < l; i++)
    if (a.indexOf(arr[i]) === -1 && arr[i] !== "") a.push(arr[i]);
  return a;
}

/*
export default function el($vyraz, $val = false) {
  if ($val && _.isArray($val)) {
    return expand($vyraz + "{" + implode($val, "") + "}");
  } else if ($val) {
    return expand($vyraz + "{" + $val + "}");
  } else {
    return expand($vyraz);
  }
}
*/

class Fce {
  prom(key) {
    //Lc.log(window.LdzJs);
    return this.get(window.LdzJs, "" + key) || false;
  }
  has(obj, key) {
    return key.split(".").every(function(x) {
      if (typeof obj != "object" || obj === null || !(x in obj)) return false;
      obj = obj[x];
      return true;
    });
  }
  get(obj, key) {
    return key.split(".").reduce(function(o, x) {
      return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
  }
  isNull(pom){
    return pom ==null ? true : false;
    //return typeof pom;
    //return typeof pom == 'undefined' ? true : false;
  }
  getDataLayer() {
    var c = window.dataLayer || false;
    if (!c) return false;

    return this.get(c, "0.Ladzo");
  }

  nl2br(str) {
    if (typeof str === "undefined" || str === null) {
      return "";
    }
    var breakTag = "<br>";
    return (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + breakTag + "$2"
    );
  }
  /*
  rozdelni klice a hodnoty pole do jednoho retezce
*/
  objectKeysValuesJoin(Obj, sep = ",", sepV = ":") {
    let arrRes = [];
    for (let key in Obj) {
      let val = Obj[key];
      arrRes.push(key + sepV + val);
    } //for
    if (!arrRes.length) return false;
    return arrRes.join(sep);
  }

  removeArrayElementByValue(val, arr) {
    let index = arr.indexOf(val);
    if (index !== -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  prevedObjectNaPoleObjektu(obj) {
    let arr = [];
    let V;
    for (var k in obj) {
      V = obj[k];
      arr.push(V);
    } //for
    return arr;
  }

  phoneLink(tel) {
    return "tel:" + tel.replaceAll(" ", "");
  }
  emailLink(email) {
    return "mailto:" + email;
  }
  menaEuro() {
    if (window.dataLayer[0]["shoptet"]["currency"] == "EUR") return true;
    return false;
  }
  vratMenu() {
    return getShoptetDataLayer("currency");
  }
  prevedFormatujDleMeny(price, kurz = true, mena = true) {
    if (kurz === true) {
      kurz = getShoptetDataLayer("currencyInfo").exchangeRate;
    }
    if (mena === true) {
      mena = getShoptetDataLayer("currency");
    }
    let c = kurz * price;
    //c = Math.round(c * 10) / 10;
    if (mena == "CZK") {
      //c = Math.round(c * 10) / 10;
      c = c.formatCisla(0, ".", " ") + " Kč";
    } else if (mena == "EUR") {
      c = "€" + c.formatCisla(1, ".");
    }

    Lc.log("meny", [price, kurz, mena, c]);
    return c;
  }
}

var MojeFce = new Fce();
export { MojeFce };

//var exports = {};
//exports.default = el;
