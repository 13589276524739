import { LadzoConsole as Lc } from "../global/ladzo-console.js";
import { MojeFce as Fce } from "../global/fce.js";
import JSAlert from "js-alert";
import { Dropzone } from "dropzone";
import "jquery-clock-timepicker/jquery-clock-timepicker.min.js";
import ColorPicker from "@thednp/color-picker";

import { createApp } from "vue";
import AppNavZnacky from "../vue/admin/editFormNavZnacky.vue";

var neon = require("neon-js");

require("jquery-flexdatalist/jquery.flexdatalist.min.js");

function smazani_radku() {
  $(".aSmazRadek").click(function(event) {
    //var $idProduktu = $(this).data("produkt");
    let href = $(this).attr("href");
    //var stav = zobrazFormularProNakup($idProduktu, false);

    JSAlert.confirm("Opravdu chete smazat tento řádek?").then(function(result) {
      // Check if pressed yes
      if (!result) return;

      // User pressed yes!
      //JSAlert.alert("File deleted!");
      window.location.href = href;
    });
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
  });
}

function nazevTeckami(pom) {
  pom = pom.replaceAll("][", ".");
  pom = pom.replaceAll("]", "");
  pom = pom.replaceAll("[", ".");
  return pom;
}

function upload_souboru() {
  $("div.uplObr").each(function() {
    let poradi = $(this).data("poradi");

    let idNeon = $(this).data("arr-id");
    Lc.log("idNeon", idNeon);
    let parametry = [];
    let DL = Fce.getDataLayer();
    if (DL) {
      //parametry = DL.neon_admin[poradi];

      parametry = Fce.get(DL.neon_admin, idNeon);
    }
    Lc.log("parametry", parametry);
    Lc.log("DL", DL);
    var dropzone = new Dropzone("div.dvUpl" + poradi, {
      url: "/ajax/upload.php",
      addRemoveLinks: true,
      /*params: {
      foo: "bar"
    },*/
      dictDefaultMessage: "Vložte soubor či jej přetáhněte sem",
      params: () => parametry,
      /*
      params: () => ({
        hello: "world"
      }),
      */
      maxFiles: 1,
      maxFilesize: 5,
      thumbnailWidth: parametry.thumbnailWidth || 120,
      thumbnailHeight: parametry.thumbnailHeight || 120,
      init: function() {
        let myDropzone = this;
        let el = $(myDropzone.element);
        Lc.log("el", el);
        let name = $(el).data("name");
        let poradi = $(el).data("poradi");
        let idArrId = $(el).data("arr-id");
        Lc.log("poradi", poradi);
        Lc.log("name", name);
        Lc.log("DL", DL);

        Lc.h("init");
        Lc.log("name", name);
        Lc.log("idArrId", idArrId);

        let inptN = $('.pole_fdxName[data-arr-id="' + idArrId + '"]');
        let inptS = $('.pole_fdxSize[data-arr-id="' + idArrId + '"]');

        //let defValue = Fce.get(DL, inptN.attr("name"));

        let defValue = Fce.get(DL, nazevTeckami(inptN.attr("name")));
        let defValueS = Fce.get(DL, nazevTeckami(inptS.attr("name")));

        Lc.h("proverit", [
          defValue,
          DL,
          inptN,
          nazevTeckami(inptN.attr("name"))
        ]);

        /*
        let arrNames = Fce.get(DL, "pole." + name) || false;
        let arrSizes = Fce.get(DL, "pole." + name + "_size") || false;
        Lc.log("nazevSouboru", arrNames);
        Lc.log("arrSizes", arrSizes);
        Lc.log("d", arrNames.length);
        */
        //if (false && Object.keys(arrNames).length && Object.keys(arrSizes).length) {
        if (defValue) {
          let mockFile = {
            name: defValue,
            size: defValueS,
            accepted: true
          };
          /*
            let mockFile = {
            name: arrNames[poradi],
            size: arrSizes[poradi],
            accepted: true
          };
          */
          Lc.log("mockFile", mockFile);
          //myDropzone.emit("addedfile", mockFile);
          //myDropzone.emit("thumbnail", mockFile, "/obr/edit/" + mockFile.name);
          //myDropzone.emit("complete", mockFile);
          myDropzone.files.push(mockFile); // add to files array
          myDropzone.emit("addedfile", mockFile);
          myDropzone.emit("thumbnail", mockFile, "/obr/edit/" + mockFile.name);
          myDropzone.emit("complete", mockFile);

          if (DL) {
            //parametry = DL.neon_admin[poradi];
            Lc.h("fdsafadsfa");
            let c;
            let parametry = Fce.get(DL.neon_admin, idNeon);
            if ((c = parametry.thumbnailWidth)) {
              el.find(".dz-image").css("width", c + "px");
            }
            if ((c = parametry.thumbnailHeight)) {
              el.find(".dz-image").css("height", c + "px");
            }
          }

          /*
          myDropzone.options.addedfile.call(myDropzone, mockFile);
          myDropzone.options.thumbnail.call(
            myDropzone,
            mockFile,
            "/obr/edit/" + mockFile.name
          );
          myDropzone.emit("complete", mockFile);
        */
        }

        this.on("maxfilesexceeded", function(file) {
          Lc.log(44444);
          this.removeAllFiles();
          this.addFile(file);
        });

        //var mockFile = { name: value.name, size: value.size };

        //myDropzone.emit("addedfile", mockFile);
        //myDropzone.emit("thumbnail", mockFile, value.path);
        //myDropzone.emit("complete", mockFile);

        //nacteni existujicich obrazku
      }
    });

    /*
var dL=getDataLayer() || false;
                        if(dL){
                            var osoba= dL.akce_osoba || false;
                            if(osoba!=data){
                                return   '<a href="'+dL.akce_url+'&makler='+data+'">'+dL.akce+'</a>';
                            }
                        }

*/

    dropzone.on("success", function(file, response) {
      response = JSON.parse(response);
      file.nominationId = response.nominationId;
      file.id = response.id;
      file.media = response.media;
      file.viewLink = response.viewLink;
      Lc.h("success");
      Lc.log("response", response);
      Lc.log("file", file);

      let fileOutName = response.fileOutName;
      let sizeOfFile = response.size;
      let el = $(dropzone.element);
      //let name = el.data("name");
      //let poradi = el.data("poradi");
      let idNeon = el.data("arr-id");
      let idArr = el.data("arr-id");

      let DL = Fce.getDataLayer();
      if (DL) {
        //parametry = DL.neon_admin[poradi];
        Lc.h("fdsafadsfa");
        let c;
        let parametry = Fce.get(DL.neon_admin, idNeon);
        if ((c = parametry.thumbnailWidth)) {
          el.find(".dz-image").css("width", c + "px");
        }
        if ((c = parametry.thumbnailHeight)) {
          el.find(".dz-image").css("height", c + "px");
        }
      }
      Lc.log("idNeon", idNeon);

      //el.find(".dz-image").css("width", "460px");
      //el.find(".dz-image").css("height", "560px");
      $('.pole_fdxName[data-arr-id="' + idArr + '"]').val(fileOutName);
      $('.pole_fdxSize[data-arr-id="' + idArr + '"]').val(sizeOfFile);
      //$(".pole_" + name + "_" + poradi).val(fileOutName);
      //$(".pole_" + name + "_size_" + poradi).val(sizeOfFile);
      //var fileuploded = file.previewElement.querySelector("[data-dz-name]");
      //fileuploded.innerHTML = serverResponse.newfilename;
      //fileuploded.innerHTML = "kocka.jpg";
    });

    dropzone.on("addedfile", (file, data) => {
      Lc.h("addedfile");
      // Add an info line about the added file for each file.
      Lc.log("file", file);
      Lc.log(`${file.name}`);
      Lc.log("dropzone", dropzone);
      Lc.log("response", data);
      //let el = $(dropzone.element);
      //let name = el.data("name");
      //let poradi = el.data("poradi");

      //$(".pole_" + name + "_" + poradi).val(`${file.name}`);
      //output.innerHTML += `<div>File added: ${file.name}</div>`;
    });

    dropzone.on("removedfile", file => {
      // Add an info line about the added file for each file.
      Lc.f("dropzone removedfile");
      Lc.log(`${file.name}`);

      let el = $(dropzone.element);
      let name = el.data("name");
      let poradi = el.data("poradi");

      let txtPole = $(".pole_" + name + "_" + poradi);
      let soubor = txtPole.val();
      Lc.log("smazat", soubor);
      txtPole.val("");

      let sizePole = $(".pole_" + name + "_size_" + poradi);
      sizePole.val("");

      if (soubor.length > 0) {
        $.post("/ajax/upload.php?delete", {
          nazevSouboru: soubor,
          poradiSouboru: poradi
        }).done(function(data) {
          Lc.log("Data Loaded: " + data);
        });
      }

      //$(".pole_" + name + "_" + poradi).val("");

      //output.innerHTML += `<div>File added: ${file.name}</div>`;
    });
  });

  /*
  dropzone.prototype.defaultOptions.init = function() {
    this.hiddenFileInput.removeAttribute("multiple");
    this.on("maxfilesexceeded", function(file) {
      this.removeAllFiles();
      this.addFile(file);
    });
  };
  */
  //let mockFile = { name: "Filename", size: 12345 };
  //dropzone.displayExistingFile(mockFile, "https://image-url");

  //const output = document.querySelector("#output");
}
/*
function upload_souboru1() {
  const dropzone = new Dropzone("div.uplObr", {
    url: "/ajax/upload.php",
    //params: {
    //  foo: "bar"
    //},
    params: () => ({
      hello: "world"
    }),
    maxFiles: 1,
    init: function() {
      this.on("maxfilesexceeded", function(file) {
        this.removeAllFiles();
        this.addFile(file);
      });
    }
  });
  Lc.log(dropzone);
  
  dropzone.prototype.defaultOptions.init = function() {
    this.hiddenFileInput.removeAttribute("multiple");
    this.on("maxfilesexceeded", function(file) {
      this.removeAllFiles();
      this.addFile(file);
    });
  };
  

  let mockFile = { name: "Filename", size: 12345 };
  dropzone.displayExistingFile(mockFile, "https://image-url");

  //const output = document.querySelector("#output");

  dropzone.on("addedfile", file => {
    // Add an info line about the added file for each file.
    Lc.log(`${file.name}`);
    //output.innerHTML += `<div>File added: ${file.name}</div>`;
  });
}
*/

function zobrazeni_time_pickeru() {
  let DL = Fce.getDataLayer();
  let parametry = {};
  if (DL) {
    parametry = Fce.get(DL.neon_admin, "setting.time_all") || [];
    //DL.neon_admin["setting"];
  }

  $(".inptTime").clockTimePicker(parametry);
}
function zobrazeni_color_pickeru() {
  Lc.f("zobrazeni_color_pickeru");
  if (!$(".inpt.color-preview").length) return false;
  //$("form.frmEdF").attr("onsubmit", "return false;");
  $(".inpt.color-preview").each(function() {
    $(this).wrap('<div class="color-picker"/>');
  });
  //debugger;

  let myPicker = new ColorPicker(".color-preview");

  /*
  $("form.frmEdF input.btsave").on("click", function() {
    $("form.frmEdF").removeAttr("onsubmit");
  });
*/
  /*$("form.frmEdF").on("keyup keypress", function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      Lc.log("cloickkfdsa");
      e.preventDefault();
      return false;
    }
  });



  
  */
  $("form.frmEdF .color-preview").on("keyup keypress", function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      let v = $("form.frmEdF .color-preview").val();
      //Lc.log("ee", e.target);
      //Lc.log("cloickkfdsa", $(this).val());
      myPicker.color = new ColorPicker.Color(v, "hex");

      // call the update
      myPicker.update();
      myPicker.hide();

      e.preventDefault();
      return false;
    }
  });
}

function neon_decode(val) {
  let txtOut = $('form.frmEdF textarea[data-neonresult="1"]');
  try {
    //var result = neon.decode("foo: {bar: 123}");
    var result = neon.Dumper.toText(neon.decode(val));
    //var data = { foo: "bar" };
    //var result = neon.encode(data, neon.BLOCK);
    Lc.log("restult", result);
    txtOut.val(result);
  } catch (e) {
    if (e instanceof neon.Error) {
      Lc.err(e);
    }
    //throw e;
    Lc.err(e);
  }
}
function neon_textarea() {
  Lc.f("neon_textarea");
  let txtIn = $('form.frmEdF textarea[data-neon="1"]');
  if (!txtIn.length) return false;
  neon_decode(txtIn.val());
  txtIn.keyup(function() {
    Lc.log("change");

    neon_decode($(this).val());
  });

  txtIn.on("keydown", function(event) {
    if (event.which == 9) {
      var start = this.selectionStart;
      var end = this.selectionEnd;

      var target = event.target;
      var value = target.value;

      // set textarea value to: text before caret + tab + text after caret
      target.value = value.substring(0, start) + "\t" + value.substring(end);

      // put caret at right position again (add one for the tab)
      this.selectionStart = this.selectionEnd = start + 1;

      event.preventDefault();
    }
  });
}

function navigace_znacky() {
  Lc.f("navigace_znacky 1");

  const app = createApp(AppNavZnacky, {});
  app.mount("#dvVueZnacky");

  let DL = Fce.getDataLayer();
  //Lc.h("DL", DL);
  let defaults = Fce.get(DL, "pole.callbackEdxArr.znacky") || [];
  //Lc.log("defaults", defaults);

  $(document).trigger("ladzo:flexdatalist.value", [
    $(".inptZnacky").val(),
    defaults
  ]);

  $(".inptZnacky").flexdatalist({
    minLength: 0,
    valueProperty: "znacka",
    textProperty: "{znacka}",
    searchIn: "znacka",
    selectionRequired: true,
    multiple: true,
    valuesSeparator: "~",
    data: "https://admin.velosprint.sk/ajax/ajax-vrat-znacky.php"
  });

  Lc.log("fdas", $("input.flexdatalist").length);
  $("input.flexdatalist").on("change:flexdatalist", function(
    event,
    set,
    options
  ) {
    Lc.f("change:flexdatalist", [event, set, options, $(this).val()]);
    $(document).trigger("ladzo:flexdatalist.value", [$(this).val(), false]);
  });

  /*
  $("input.flexdatalist").on("change", function(event, set, options) {
    Lc.f("change", [event, set, options]);
  });
  Lc.log('$("input.flexdatalist")', $("input.flexdatalist").length);
  */
}
function zobrazeni_zalozek() {
  let form = $("form.frmEdF");
  let taby = form.find("div[data-ldz-tabs]");
  if (!taby.length) return false;
  taby.wrapAll('<div class="dvFrmTabs"/>');
  let div = form.find(".dvFrmTabs");

  let arr = [];
  taby.each(function() {
    let d = $(this);

    let cil = d.data("ldz-tabs");
    let title = d.find(">h2.ldz-tab-title").text();

    Lc.log("d", [cil, d.html()]);
    arr.push(`<li><a href="#ldzTab-${cil}">${title}</a></li>`);
  });

  div.prepend(`<ul class="ulTabs">${arr.join("")}</ul>`);

  div.tabs();
}
export function uprava_stranky() {
  Lc.f("uprava_stranky");
  smazani_radku();
  upload_souboru();
  zobrazeni_time_pickeru();
  zobrazeni_color_pickeru();
  neon_textarea();
  zobrazeni_zalozek();

  navigace_znacky();
}
