import { LadzoConsole as Lc } from "../global/ladzo-console.js";
//import { MojeFce as Fce } from "../global/fce.js";
import JSAlert from "js-alert";

function smazani_radku() {
  $(".aSmazRadek").click(function(event) {
    //var $idProduktu = $(this).data("produkt");
    let href = $(this).attr("href");
    //var stav = zobrazFormularProNakup($idProduktu, false);

    JSAlert.confirm("Opravdu chete smazat tento řádek?").then(function(result) {
      // Check if pressed yes
      if (!result) return;

      // User pressed yes!
      //JSAlert.alert("File deleted!");
      window.location.href = href;
    });
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
  });
}

export function uprava_stranky() {
  Lc.f("uprava_stranky");
  smazani_radku();
}
